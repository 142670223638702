//モジュールのインポート
import 'jquery.easing';  //jquery.easing読み込み
import script_loadingSplash from './modules/script_loadingSplash';    //ローディング（スプラッシュ）読み込み
import script_shortHeader from './modules/script_shortHeader';        //ショートヘッダ開閉読み込み
import script_hamburger from './modules/script_hamburger';            //ハンバーガーメニュー読み込み
import script_telNonPc from './modules/script_telNonPc';              //PC電話発信なし読み込み

//モジュール先にある関数実行
script_loadingSplash();   //ローディング（スプラッシュ）スクリプト実行
script_shortHeader();     //ショートヘッダ開閉スクリプト実行
script_hamburger();       //ハンバーガーメニュースクリプト実行
script_telNonPc();        //PC電話発信なしスクリプト実行