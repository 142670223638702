export default function script_shortHeader() {
  $(function() {
    const defaultHeader   = $('.GlobalNav__default'),
          shortHeader     = $('.GlobalNav__short'),
          shortHeaderBody = $('.GlobalNav__body--short'),
          breakPoint      = 1025,  //ブレイクポイント
          showHeight      = 154;
  
    $(window).on('scroll resize load', function(){
      const winWidth = $(window).width();
  
      shortHeaderBody.attr('style', '');   //以前に設定されたインラインstyleをクリアする
  
      if (winWidth >= breakPoint){  //PCの場合
        if ($(window).scrollTop() >= showHeight){
          shortHeader.slideDown();
        } else {
          shortHeader.slideUp();
        }
      } else {
        shortHeader.attr('style', '');   //以前に設定されたインラインstyleをクリアする
      }
    });
  });
}
